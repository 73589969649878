import React from 'react';

const Loader = () => {
  return (
    <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div class="spinner-border" role="status" style={{ color: "#990d0e" }}>
        <span class="sr-only"></span>
      </div>
    </div>
  )
}

export default Loader;